<template>
	<router-view />

	<Navbar />
</template>

<script>
import Navbar from '@/components/NavbarComponent.vue';

export default {
	components: {
		Navbar
	},

	data() {
		return {
		};
	},

	mounted() {
		if (!this.TWA.isExpanded) {
			this.TWA.expand();
		}
		
		this.TWA.ready();
	}
}
</script>