import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'space',
		component: () => import('@/views/SpaceView.vue')
	},
	{
		path: '/tasks',
		name: 'tasks',
		component: () => import('@/views/TasksView.vue')
	},
	{
		path: '/invite',
		name: 'invite',
		component: () => import('@/views/InviteView.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

export default router
