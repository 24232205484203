import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

import '@/assets/css/bootstrap.min.css'
import '@/assets/css/main.css'

const app = createApp(App)

// app.use(store)
app.use(router)

app.config.globalProperties.TWA = window.Telegram.WebApp
app.mount('#app')