<template>
    <div class="navbar py-3 px-4 w-100"
        style="background: rgb(237, 237, 237, .1); backdrop-filter: blur(3px); -webkit-backdrop-filter: blur(3px);">

        <a @click.prevent="toRoute('space', 1)" class="col d-flex flex-column align-items-center p-1" href="#"
            :class="{ 'active_navtab': activeNavTab === 1, 'unactive_navtab': activeNavTab !== 1 }">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M16.5 3.44338L26.6244 9.28868C26.9338 9.46731 27.1244 9.79743 27.1244 10.1547V21.8453C27.1244 22.2026 26.9338 22.5327 26.6244 22.7113L16.5 28.5566C16.1906 28.7353 15.8094 28.7353 15.5 28.5566L5.37564 22.7113C5.06624 22.5327 4.87564 22.2026 4.87564 21.8453V10.1547C4.87564 9.79743 5.06624 9.46731 5.37564 9.28868L15.5 3.44338C15.8094 3.26474 16.1906 3.26474 16.5 3.44338Z"
                    stroke="url(#paint0_linear_27_108)" stroke-width="2" />
                <path
                    d="M15.5 7.88853C15.8094 7.7099 16.1906 7.7099 16.5 7.88853L22.7746 11.5112C23.084 11.6898 23.2746 12.0199 23.2746 12.3772V19.6225C23.2746 19.9798 23.084 20.3099 22.7746 20.4885L16.5 24.1112C16.1906 24.2898 15.8094 24.2898 15.5 24.1112L9.22536 20.4885C8.91596 20.3099 8.72536 19.9798 8.72536 19.6225V12.3772C8.72536 12.0199 8.91596 11.6898 9.22536 11.5112L15.5 7.88853Z"
                    fill="#FCFCFC" />
                <g clip-path="url(#clip0_27_108)">
                    <path
                        d="M17.3395 15.126C17.3395 15.126 17.7156 15.5114 18.4535 14.8393C19.1914 14.1672 19.7039 14.3411 20.0235 14.689C20.3431 15.0368 20.5192 15.7532 19.3452 16.4109C18.1513 17.0798 17.1846 15.8829 17.1846 15.8829L17.3397 15.126H17.3395Z"
                        fill="url(#paint1_radial_27_108)" />
                    <mask id="mask0_27_108" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="17" y="14"
                        width="4" height="3">
                        <path
                            d="M17.3395 15.126C17.3395 15.126 17.7156 15.5114 18.4535 14.8393C19.1914 14.1672 19.7039 14.3411 20.0235 14.689C20.3431 15.0368 20.5192 15.7532 19.3452 16.4109C18.1513 17.0798 17.1846 15.8829 17.1846 15.8829L17.3397 15.126H17.3395Z"
                            fill="white" />
                    </mask>
                    <g mask="url(#mask0_27_108)">
                        <path opacity="0.1"
                            d="M17.942 15.7639C17.942 15.7639 18.3182 16.1493 19.056 15.4772C19.7939 14.8051 20.3065 14.9791 20.6261 15.3269C20.9457 15.6748 21.1217 16.3911 19.9477 17.0489C18.7538 17.7177 17.7871 16.5208 17.7871 16.5208L17.9423 15.7639H17.942Z"
                            fill="url(#paint2_linear_27_108)" />
                        <path opacity="0.14"
                            d="M14.6881 13.6552C14.6881 13.6552 15.0656 13.5917 15.7657 14.8542C16.4659 16.1166 17.6649 17.556 19.561 16.3262C19.561 16.3262 18.7358 16.4525 17.8293 14.9603C16.835 13.3235 16.0592 12.8335 14.6881 13.6552Z"
                            fill="#0A0A0A" />
                    </g>
                    <path
                        d="M11.7391 15.335C11.7391 15.335 12.134 15.2127 13.0646 16.4631C13.9952 17.7135 15.4853 19.0811 17.3372 17.4926C17.3372 17.4926 16.4724 17.7464 15.2877 16.2799C13.9889 14.6715 13.0882 14.2585 11.7391 15.335Z"
                        fill="url(#paint3_linear_27_108)" />
                    <mask id="mask1_27_108" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="11" y="14"
                        width="7" height="5">
                        <path
                            d="M11.7391 15.335C11.7391 15.335 12.134 15.2127 13.0646 16.4631C13.9952 17.7135 15.4853 19.0811 17.3372 17.4926C17.3372 17.4926 16.4724 17.7464 15.2877 16.2799C13.9889 14.6715 13.0882 14.2585 11.7391 15.335Z"
                            fill="white" />
                    </mask>
                    <g mask="url(#mask1_27_108)">
                        <path opacity="0.1"
                            d="M10.9519 15.8173C10.9519 15.8173 11.3549 15.6925 12.3051 16.9691C13.2554 18.2454 14.7766 19.642 16.6671 18.0201C16.6671 18.0201 15.7842 18.2792 14.5748 16.7819C13.2489 15.1401 12.3292 14.7186 10.9519 15.8173Z"
                            fill="#FCFCFC" />
                    </g>
                    <path
                        d="M14.3038 14.3316C14.3038 14.3316 14.6693 14.2186 15.5304 15.3757C16.3917 16.5327 17.7707 17.7984 19.4845 16.3282C19.4845 16.3282 18.6841 16.5631 17.5879 15.2059C16.3859 13.7176 15.5522 13.3355 14.3038 14.3316Z"
                        fill="url(#paint4_linear_27_108)" />
                    <mask id="mask2_27_108" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="14" y="13"
                        width="6" height="4">
                        <path
                            d="M14.3038 14.3316C14.3038 14.3316 14.6693 14.2186 15.5304 15.3757C16.3917 16.5327 17.7707 17.7984 19.4845 16.3282C19.4845 16.3282 18.6841 16.5631 17.5879 15.2059C16.3859 13.7176 15.5522 13.3355 14.3038 14.3316Z"
                            fill="white" />
                    </mask>
                    <g mask="url(#mask2_27_108)">
                        <path opacity="0.1"
                            d="M13.5754 14.7781C13.5754 14.7781 13.9485 14.6626 14.8277 15.844C15.7071 17.0251 17.1148 18.3175 18.8645 16.8165C18.8645 16.8165 18.0474 17.0562 16.9283 15.6707C15.7011 14.1512 14.8499 13.7609 13.5754 14.7781Z"
                            fill="#FCFCFC" />
                    </g>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_27_108" x1="2" y1="16.0016" x2="30" y2="16.0016"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#069F7A" />
                        <stop offset="0.2" stop-color="#0CA77B" />
                        <stop offset="0.5" stop-color="#1FBD80" />
                        <stop offset="0.72" stop-color="#31D284" />
                    </linearGradient>
                    <radialGradient id="paint1_radial_27_108" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(19.3785 15.4623) scale(4.07339)">
                        <stop offset="0.14" stop-color="#017771" />
                        <stop offset="0.41" stop-color="#31D284" />
                        <stop offset="0.58" stop-color="#33CC99" />
                    </radialGradient>
                    <linearGradient id="paint2_linear_27_108" x1="18.0477" y1="16.8615" x2="20.7926" y2="15.4717"
                        gradientUnits="userSpaceOnUse">
                        <stop offset="0.16" stop-color="white" />
                        <stop offset="0.32" stop-color="white" stop-opacity="0.79" />
                        <stop offset="0.66" stop-color="white" stop-opacity="0.37" />
                        <stop offset="0.89" stop-color="white" stop-opacity="0.1" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_27_108" x1="11.7391" y1="16.4733" x2="17.3374" y2="16.4733"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#069F7A" />
                        <stop offset="0.2" stop-color="#0CA77B" />
                        <stop offset="0.5" stop-color="#1FBD80" />
                        <stop offset="0.72" stop-color="#31D284" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_27_108" x1="14.3038" y1="15.3852" x2="19.4845" y2="15.3852"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#069F7A" />
                        <stop offset="0.2" stop-color="#0CA77B" />
                        <stop offset="0.5" stop-color="#1FBD80" />
                        <stop offset="0.72" stop-color="#31D284" />
                    </linearGradient>
                    <clipPath id="clip0_27_108">
                        <rect width="8.52174" height="4.43237" fill="white" transform="translate(11.7391 13.7781)" />
                    </clipPath>
                </defs>
            </svg>
            <span :class="{ 'text-light': activeNavTab === 1, 'text-muted': activeNavTab !== 1 }">Space</span>
        </a>

        <a @click.prevent="toRoute('tasks', 2)" class="col d-flex flex-column align-items-center" href="#" :class="{ 'active_navtab': activeNavTab === 2, 'unactive_navtab': activeNavTab !== 2 }">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M9.6923 21.3846L5.84614 26L3.53845 24.4615M9.6923 13.6923L5.84614 18.3077L3.53845 16.7692M9.6923 6L5.84614 10.6154L3.53845 9.07692"
                    stroke="url(#paint0_linear_27_140)" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" />
                <rect x="13" y="7" width="16" height="2" rx="1" fill="#0A0A0A" />
                <rect x="13" y="15" width="16" height="2" rx="1" fill="#0A0A0A" />
                <rect x="13" y="23" width="16" height="2" rx="1" fill="#0A0A0A" />
                <defs>
                    <linearGradient id="paint0_linear_27_140" x1="3.53845" y1="16.0012" x2="9.6923" y2="16.0012"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#069F7A" />
                        <stop offset="0.2" stop-color="#0CA77B" />
                        <stop offset="0.5" stop-color="#1FBD80" />
                        <stop offset="0.72" stop-color="#31D284" />
                    </linearGradient>
                </defs>
            </svg>
            <span :class="{ 'text-light': activeNavTab === 2, 'text-muted': activeNavTab !== 2 }">Task</span>
        </a>

        <a @click.prevent="toRoute('invite', 3)" class="col d-flex flex-column align-items-center" href="#" :class="{ 'active_navtab': activeNavTab === 3, 'unactive_navtab': activeNavTab !== 3 }">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path
                    d="M23.7867 23.9735V18.6535M23.7867 18.6535V13.3335M23.7867 18.6535H18.4667M23.7867 18.6535H29.1067"
                    stroke="url(#paint0_linear_27_150)" stroke-width="2.11111" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path
                    d="M17.2 26C17.2 23.2018 13.7974 20.9333 9.6 20.9333C5.40264 20.9333 2 23.2018 2 26M9.6 17.1333C6.80176 17.1333 4.53333 14.8649 4.53333 12.0667C4.53333 9.26842 6.80176 7 9.6 7C12.3982 7 14.6667 9.26842 14.6667 12.0667C14.6667 14.8649 12.3982 17.1333 9.6 17.1333Z"
                    stroke="#0A0A0A" stroke-width="2.11111" stroke-linecap="round" stroke-linejoin="round" />
                <defs>
                    <linearGradient id="paint0_linear_27_150" x1="18.4667" y1="18.6541" x2="29.1067" y2="18.6541"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#069F7A" />
                        <stop offset="0.2" stop-color="#0CA77B" />
                        <stop offset="0.5" stop-color="#1FBD80" />
                        <stop offset="0.72" stop-color="#31D284" />
                    </linearGradient>
                </defs>
            </svg>
            <span :class="{ 'text-light': activeNavTab === 3, 'text-muted': activeNavTab !== 3 }">Invite</span>
        </a>

    </div>
</template>

<script>
export default {
    data() {
        return {
            activeNavTab: 1
        };
    },

    methods: {
        toRoute(routeName, navTab) {
            this.$router.replace({ name: routeName });
            this.activeNavTab = navTab;
        }
    },

    mounted() {
        let currentPage = window.location.pathname;
        if (currentPage.includes('tasks')) {
            this.activeNavTab = 2;
        }else if (currentPage.includes('invite')) {
            this.activeNavTab = 3;
        }
    }
}
</script>

<style>
.active_navtab {
    background: rgb(48, 208, 132, .2);
    border-radius: 8px;
    flex: 0 0 30%;
    text-decoration: none;
}

.unactive_navtab {
    flex: 0 0 30%;
    text-decoration: none;
}
</style>